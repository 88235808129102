@import 'custom';
@import '~leaflet/dist/leaflet.css';

/**
 * Layout
*/
.layout-topleft {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10000;
}

@media screen and (max-width: 1024px) {
  .layout-topleft {
    position: absolute;
    top: 5px;
    left: 0px;
    z-index: 10000;
    width: 100%;
  }

  .card-address-inputgroup {
    width: 100%;
  }
}

.tooltip {
  z-index: 100000 !important;
}

/**
 * Map
*/
@media screen and (max-width: 1024px) {
  .leaflet-top {
    top: 80px;
  }
}

/**
 * SearchAddress
 */
 .rbt {
  min-width: 500px;
}

.rbt .rbt-menu {
  z-index: 10001;
}

@media screen and (max-width: 1024px) {
  .rbt {
    min-width: 0px;
    flex: 1;
  }
}

/**
 *
 */
.card-parcelles {
  min-width: 600px;
}

@media screen and (max-width: 1024px) {
  .card-parcelles {
    min-width: 0px;
    width: 100%;
  }
}

/**
 * Custom option
*/
.custom-option-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}

.custom-option-item svg {
  width: 110px;
  fill: $primary;
}

.custom-options-checkable div {
  max-width: 250px;
  padding: 5px;
}

.custom-option-item-question {
  position: absolute;
  right: 12px;
  padding-top: 4px;
  cursor: pointer;
}

.popover {
  max-width: 600px !important;
}

.custom-option-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.custom-option-item-check:checked + .custom-option-item {
  color: $primary;
  background-color: $light;
  border: $primary 2px solid;
}

.custom-options-checkable .custom-option-item {
  width: 100%;
  cursor: pointer;
  border-radius: 0.42rem;
  color: $primary;
  background-color: $light;
  border: none;
  box-shadow: $secondary 0px 4px 12px;
}

.custom-options-checkable .item-2 .custom-option-item {
  min-height: 350px;
  max-width: 350px;
  padding-top: 10%;
  font-size: 2em;
}

.custom-options-checkable .item-3 .custom-option-item {
  min-height: 250px;
  max-width: 350px;
  padding-top: 20%;
  font-size: 1.5em;
}